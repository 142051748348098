<template>
  <section>
    <section
      v-if="tipKorisnika === 'instruktor'"
    >
      <b-row
        class="match-height"
      >
        <b-col
          lg="12"
          md="12"
        >
          <b-card
            text-variant="center"
            :bg-variant="naslovVariant"
          >
            <b-avatar
              :variant="naslovVariant"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="AwardIcon"
              />
            </b-avatar>
            <h1
              class="mb-1 mt-50
              text-white"
            >
              {{ naslov }}
            </h1>
            <b-card-text
              class="m-auto w-75 text-white"
            >
              {{ podnaslov }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>
                {{ $t('Uslovi') }}
              </b-card-title>
            </b-card-header>

            <b-card-body>
              <div
                class="transaction-item"
              >
                <b-media
                  no-body
                >
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="lekarskoVariant"
                    >
                      <feather-icon
                        size="18"
                        icon="HeartIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6
                      class="transaction-title"
                    >
                      {{ $t('LekarskoUverenje') }}
                    </h6>
                    <small>
                      {{ podnaslovLekarsko }}
                    </small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-danger"
                >
                  {{ data.datum_isteka_lekarskog_instruktora }}
                </div>
              </div>
              <div
                class="transaction-item"
              >
                <b-media
                  no-body
                >
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="vozackaVariant"
                    >
                      <feather-icon
                        size="18"
                        icon="TruckIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6
                      class="transaction-title"
                    >
                      {{ $t('VozackaDozvola') }}
                    </h6>
                    <small>{{ podnaslovVozacka }}</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-danger"
                >
                  {{ data.datum_isteka_vozacke_dozvole }}
                </div>
              </div>
              <div
                class="transaction-item"
              >
                <b-media
                  no-body
                >
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="licencaInstruktoraVariant"
                    >
                      <feather-icon
                        size="18"
                        icon="EditIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6
                      class="transaction-title"
                    >
                      {{ $t('LicencaInstruktoraVoznje') }}
                    </h6>
                    <small>{{ podnaslovLicencaInstruktora }}</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-danger"
                >
                  {{ data.datum_isteka_licence_instruktora }}
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        class="match-height"
      >
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div
              class="bg-light-primary rounded-top text-center"
            >
              <b-img
                :src="require('@/assets/images/illustration/email.svg')"
                alt="Meeting Pic"
                height="170"
              />
            </div>
            <b-card-body>
              <!-- metting header -->
              <div
                class="meetup-header d-flex align-items-center"
              >
                <div class="meetup-day">
                  <h3 class="mb-0">
                    {{ tekuciDatum }}
                  </h3>
                </div>
                <div
                  class="my-auto"
                >
                  <b-card-title
                    class="mb-25"
                  >
                    {{ $t('ObavestenjaOObuci') }}
                  </b-card-title>
                  <b-card-text
                    class="mb-0"
                  >
                    {{ $t('Vazno!!! ObaveznoProcitati') }}
                  </b-card-text>
                </div>
              </div>
              <!--/ metting header -->

              <!-- media -->
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    :variant="data.pauza_jedan_dan_u_nedelji ? 'light-success' : 'light-danger'"
                    size="34"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6
                    class="mb-0"
                  >
                    {{ $t('PauzaOdJednogDana') }}
                  </h6>
                  <small>{{ data.pauza_jedan_dan_u_nedelji ? $t('UslovJeURedu') : $t('UslovNijeURedu') }}</small>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    :variant="data.pauza_izmedju_casova ? 'light-success' : 'light-danger'"
                    size="34"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6
                    class="mb-0"
                  >
                    {{ $t('PauzaIzmedjuCasova') }}
                  </h6>
                  <small>{{ data.pauza_izmedju_casova ? $t('UslovJeURedu') : $t('UslovNijeURedu') }}</small>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    rounded
                    :variant="data.pauza_prethodni_dan ? 'light-success' : 'light-danger'"
                    size="34"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6
                    class="mb-0"
                  >
                    {{ $t('PauzaOdPrethodnogDana') }}
                  </h6>
                  <small>{{ data.pauza_prethodni_dan ? $t('UslovJeURedu') : $t('UslovNijeURedu') }}</small>
                </b-media-body>
              </b-media>

            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            no-body
          >
            <b-card-header>
              <b-card-title
                class="ml-25"
              >
                {{ $t('Casovi') }}
              </b-card-title>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <app-timeline>
                <app-timeline-item
                  v-for="(cas, index) in data.casovi"
                  :key="index"
                  :title="cas.ime_kandidata"
                  :subtitle="cas.datum + ' ' + cas.vreme_pocetka + ' - ' + cas.vreme_zavrsetka"
                  :time="cas.kod_casa"
                  variant="info"
                />
              </app-timeline>
              <!--/ timeline -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <section
      v-if="tipKorisnika === 'admin'"
    >
      <b-row
        class="match-height"
      >
        <b-col
          lg="12"
          md="12"
        >
          <b-card
            text-variant="center"
            class="bg-gradient-info"
          >
            <b-avatar
              size="70"
              class="shadow mb-2 bg-primary bg-darken-4"
            >
              <feather-icon
                size="28"
                icon="AwardIcon"
              />
            </b-avatar>
            <h1
              class="mb-1 mt-50
              text-white"
            >
              {{ $t('Dobrodosli') }}
            </h1>
            <b-card-text
              class="m-auto w-75 text-white"
            >
              {{ $t('NaslovnaStrana') }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <casovi />
      <b-row>
        <b-col
          lg="12"
          md="6"
        >
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>
                {{ $t('DatumiVozila') }}
              </b-card-title>
            </b-card-header>

            <b-card-body>
              <b-table
                v-if="Object.entries(datumiVozilaPristizu).length"
                :items="datumiVozilaPristizu"
                :fields="poljaVozila"
                striped
                responsive
                class="mb-0"
              >
                <template #cell(detalji)="row">
                  <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    @change="row.toggleDetails"
                  />
                </template>
                <template #cell(id)="dataVozilo">
                  <b-button-group size="sm">
                    <router-link
                      :to="{
                        name: 'autoskola-vozila-izmeni',
                        params: { id: dataVozilo.item.id }
                      }"
                    >
                      <b-button
                        variant="info"
                        size="sm"
                      >
                        {{ $t("Izmeni") }}
                      </b-button>
                    </router-link>
                  </b-button-group>
                </template>

                <!-- full detail on click -->
                <template #row-details="row">
                  <b-card>
                    <b-table-simple
                      hover
                      small
                      responsive
                    >
                      <b-tbody>
                        <b-tr
                          :variant="row.item.istice_registracija ? 'warning' : (row.item.istekla_registracija ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumIstekaRegistracije') }}
                          </b-th>
                          <b-td>{{ row.item.datum_isteka_registracije }}</b-td>
                        </b-tr>
                        <b-tr
                          :variant="row.item.istice_sestomesecni_tehnicki ? 'warning' : (row.item.istekao_sestomesecni_tehnicki ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumIstekaSestomesecnogTehnickog') }}
                          </b-th>
                          <b-td>{{ row.item.datum_isteka_sestomesecnog_tehnickog_pregleda }}</b-td>
                        </b-tr>
                        <b-tr
                          :variant="row.item.istice_redovan_servis ? 'warning' : (row.item.istekao_redovan_servis ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('RedovanServisNa') }} {{ row.item.km_redovan_servis }}
                          </b-th>
                          <b-td>{{ $t('StanjeNaPutometru') }} {{ row.item.stanje_putometar }}</b-td>
                        </b-tr>
                        <b-tr
                          :variant="row.item.istice_prva_pomoc ? 'warning' : (row.item.istekla_prva_pomoc ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumIstekaPrvePomoci') }}
                          </b-th>
                          <b-td>{{ row.item.prva_pomoc ? row.item.datum_isteka_prve_pomoci : $t('NemaPrvuPomoc') }}</b-td>
                        </b-tr>
                        <b-tr
                          :variant="row.item.istice_pp_aparat ? 'warning' : (row.item.istekao_pp_aparat ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumIstekaPPAparata') }}
                          </b-th>
                          <b-td>{{ row.item.datum_isteka_pp_aparata }}</b-td>
                        </b-tr>
                        <b-tr
                          :variant="row.item.istice_servis_tahografa ? 'warning' : (row.item.istekao_servis_tahografa ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumServisaTahografa') }}
                          </b-th>
                          <b-td>{{ row.item.datum_servisa_tahografa }}</b-td>
                        </b-tr>
                        <b-tr
                          :variant="row.item.istice_preuzimanje_podataka_sa_digitalnog_tahografa ? 'warning' : (row.item.isteklo_preuzimanje_podataka_sa_digitalnog_tahografa ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumPreuzimanjaPodatakaTahograf') }}
                          </b-th>
                          <b-td>{{ row.item.datum_preuzimanja_podataka_tahograf }}</b-td>
                        </b-tr>
                        <b-tr
                          :variant="row.item.istice_atest_za_tng ? 'danger' : (row.item.istekao_atest_za_tng ? 'danger' : '')"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumAtestaTng') }}
                          </b-th>
                          <b-td>{{ row.item.datum_atesta_tng }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-card>
                </template>
              </b-table>
              <b-alert
                v-else
                variant="primary"
                show
              >
                <div
                  class="alert-body"
                  style="padding: 30px;"
                >
                  <span>{{ $t('DatumiVozilaSuURedu') }}</span>
                </div>
              </b-alert>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="12"
          md="6"
        >
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>
                {{ $t('DatumiKandidati') }}
              </b-card-title>
            </b-card-header>

            <b-card-body>
              <b-table
                v-if="Object.entries(datumiKandidatiPristizu).length"
                :items="datumiKandidatiPristizu"
                :fields="poljaKandidati"
                striped
                responsive
                class="mb-0"
              >
                <template #cell(detalji)="row">
                  <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    @change="row.toggleDetails"
                  />
                </template>
                <template #cell(id)="data">
                  <b-button-group size="sm">
                    <b-button
                      variant="info"
                      size="sm"
                      @click="linkIzmeni(data.item.id)"
                    >
                      {{ $t("Izmeni") }}
                    </b-button>
                    <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      right
                      variant="outline-primary"
                      :text="$t('Linkovi')"
                      size="sm"
                    >
                      <b-dropdown-item>
                        <router-link
                          :to="{
                            name: 'autoskola-kandidat-ugovori',
                            params: { id: data.item.id }
                          }"
                        >
                          {{ $t('Ugovori') }}
                        </router-link>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <router-link
                          :to="{
                            name: 'autoskola-kandidat-podaci-o-obuci-izmeni',
                            params: { id: data.item.id }
                          }"
                        >
                          {{ $t('PodaciOObuci') }}
                        </router-link>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <router-link
                          :to="{
                            name: 'autoskola-kandidat-finansije',
                            params: { id: data.item.id }
                          }"
                        >
                          {{ $t('Finansije') }}
                        </router-link>
                      </b-dropdown-item>
                    </b-dropdown>

                  </b-button-group>
                </template>

                <!-- full detail on click -->
                <template #row-details="row">
                  <b-card>
                    <b-table-simple
                      small
                      responsive
                    >
                      <b-tbody>
                        <b-tr>
                          <b-td>
                            {{ $t('IDKandidata') }}
                          </b-td>
                          <b-td>
                            {{ row.item.idkandidata }}
                          </b-td>
                        </b-tr>
                        <b-th
                          v-if="row.item.rateKojeDolaze.length > 0"
                          colspan="2"
                        >
                          {{ $t('RateKojeDolazeNaNaplatu') }}
                        </b-th>
                        <b-tr
                          v-for="rata, key in row.item.rateKojeDolaze"
                          :key="key"
                        >
                          <b-td>
                            {{ rata.datum }}
                          </b-td>
                          <b-td>
                            {{ rata.iznos }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="row.item.potrebna_pomagala"
                          variant="warning"
                        >
                          <b-th class="col-3">
                            {{ $t('PotrebnaPomagala') }}
                          </b-th>
                          <b-td>{{ row.item.potrebna_pomagala_prikaz }}</b-td>
                        </b-tr>
                        <b-tr
                          v-if="row.item.isticeTeorijskiIspit"
                          variant="warning"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumIstekaTeorijskogIspita') }}
                          </b-th>
                          <b-td>{{ row.item.datumIstekaTeorijskogIspita }}</b-td>
                        </b-tr>
                        <b-tr
                          v-if="row.item.istice_lekarsko_uverenje"
                          variant="warning"
                        >
                          <b-th class="col-3">
                            {{ $t('DatumIstekaLekarskogUverenja') }}
                          </b-th>
                          <b-td>{{ row.item.datum_isteka_lekarskog }}</b-td>
                        </b-tr>
                        <b-tr
                          v-if="row.item.obavezna_prva_pomoc"
                          variant="warning"
                        >
                          <b-th
                            colspan="2"
                            class="col-3"
                          >
                            {{ $t('ObaveznaPrvaPomoc') }}
                          </b-th>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-card>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="data.item.status === 'aktivan' ? 'success' : 'danger'">
                    {{ data.item.status }}
                  </b-badge>
                </template>
              </b-table>
              <b-alert
                v-else
                variant="primary"
                show
              >
                <div
                  class="alert-body"
                  style="padding: 30px;"
                >
                  <span>{{ $t('DatumiKandidataSuURedu') }}</span>
                </div>
              </b-alert>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>{{ $t('DuzniciKojiKasneSaPlacanjem') }}</b-card-title>
            </b-card-header>

            <b-card-body
              v-if="Object.entries(datumiRateUKasnjenju).length"
            >
              <div
                v-for="datumKasnjenje in datumiRateUKasnjenju"
                :key="datumKasnjenje.id"
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="danger"
                    >
                      <feather-icon
                        size="18"
                        icon="MinusIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ datumKasnjenje.ime }} {{ datumKasnjenje.prezime }}
                    </h6>
                    <small>
                      <router-link
                        :to="{
                          name: 'autoskola-kandidat-finansije',
                          params: { id: datumKasnjenje.id }
                        }"
                      >
                        {{ $t('Finansije') }}
                      </router-link>
                    </small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-danger"
                >
                  {{ datumKasnjenje.zaduzenje_na_danasnji_datum }}
                </div>
              </div>
            </b-card-body>
            <b-card-body
              v-else
            >
              <b-alert
                variant="primary"
                show
              >
                <div
                  class="alert-body"
                  style="padding: 30px;"
                >
                  <span>{{ $t('NePostojiKasnjenjeRata') }}</span>
                </div>
              </b-alert>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>{{ $t('DuzniciKojimaPristizuRate') }}</b-card-title>
            </b-card-header>

            <b-card-body
              v-if="Object.entries(datumiRatePristizu).length"
            >
              <div
                v-for="datumKasnjenje in datumiRatePristizu"
                :key="datumKasnjenje.id"
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="danger"
                    >
                      <feather-icon
                        size="18"
                        icon="MinusIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ datumKasnjenje.ime }} {{ datumKasnjenje.prezime }}
                    </h6>
                    <small>
                      <router-link
                        :to="{
                          name: 'autoskola-kandidat-finansije',
                          params: { id: datumKasnjenje.id }
                        }"
                      >
                        {{ $t('Finansije') }}
                      </router-link>
                    </small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-danger"
                >
                  {{ datumKasnjenje.zaduzenje_na_danasnji_datum }}
                </div>
              </div>
            </b-card-body>
            <b-card-body
              v-else
            >
              <b-alert
                variant="primary"
                show
              >
                <div
                  class="alert-body"
                  style="padding: 30px;"
                >
                  <span>{{ $t('NemaRataKojePristizu') }}</span>
                </div>
              </b-alert>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BAvatar,
  BCardText,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
  BImg,
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BFormCheckbox,
  BButton,
  BButtonGroup,
  BBadge,
  BAlert,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import Casovi from './Casovi.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,
    BImg,
    BTable,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BBadge,
    Casovi,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
      podnaslovLekarsko: this.$t('LekarskoJeURedu'),
      lekarskoVariant: 'success',
      podnaslovVozacka: this.$t('VozackaDozvolaVazi'),
      vozackaVariant: 'success',
      podnaslovLicencaInstruktora: this.$t('LicencaVazi'),
      licencaInstruktoraVariant: 'success',
      tekuciDatum: '',
      naslov: this.$t('Cestitamo'),
      podnaslov: this.$t('sve je u redu i možete da nastavite sa radom'),
      naslovVariant: 'primary',
      pauzaJedanDanUNedelji: true,
      pauzaPrethodniDan: true,
      pauzaPrethodniCas: true,
      datumiVozilaPristizu: [],
      datumiKandidatiPristizu: [],
      datumiRateUKasnjenju: [],
      datumiRatePristizu: [],
      userData: [],
      poljaVozila: [
        { key: 'detalji', label: '', thStyle: { width: '10px' } },
        'marka',
        'tip',
        { key: 'regbroj', label: 'Registarski broj' },
        { key: 'id', label: 'Akcije' },
      ],
      poljaKandidati: [
        { key: 'detalji', label: '', thStyle: { width: '10px' } },
        { key: 'ime', label: this.$i18n.t('Ime') },
        { key: 'prezime', label: this.$i18n.t('Prezime') },
        { key: 'telefon', label: this.$i18n.t('Telefon') },
        { key: 'status', label: this.$i18n.t('status') },
        { key: 'id', label: 'Akcije' },
      ],
      tipKorisnika: '',
    }
  },
  mounted() {
    this.pokupiPodatke()
    this.danasnjiDatum()
  },
  methods: {
    danasnjiDatum() {
      this.tekuciDatum = moment(new Date()).format('DD.MM.')
    },
    pokupiPodatke() {
      this.userData = JSON.parse(localStorage.getItem('userData'))

      if (this.userData.role === 'admin') {
        this.$http.get('/autoskole-administratori/datumi-isticu-vozila').then(response => {
          this.datumiVozilaPristizu = response.data
        })
        this.$http.get('/autoskole-administratori/datumi-isticu-kandidati').then(response => {
          this.datumiKandidatiPristizu = response.data
        })
        this.$http.get('/autoskole-administratori/datumi-rate-u-kasnjenju').then(response => {
          this.datumiRateUKasnjenju = response.data
        })
        this.$http.get('/autoskole-administratori/datumi-rate-pristizu').then(response => {
          this.datumiRatePristizu = response.data
        })
      }
      this.tipKorisnika = this.userData.role
      this.$http.get('/instruktori/dashboard').then(response => {
        this.data = response.data
        if (this.data.datum_isteka_lekarskog_instruktora === '') {
          this.podnaslovLekarsko = this.$t('LekarskoNijeUneto')
          this.lekarskoVariant = 'danger'
          this.naslovVariant = 'danger'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        } else if (this.data.isteklo_lekarskog_instruktora) {
          this.podnaslovLekarsko = this.$t('LekarskoJeIsteklo')
          this.lekarskoVariant = 'danger'
          this.naslovVariant = 'danger'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        } else if (this.data.istice_lekarsko) {
          this.podnaslovLekarsko = this.$t('IsticeLekarsko')
          this.lekarskoVariant = 'warning'
          this.naslovVariant = 'warning'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        }

        if (this.data.datum_isteka_vozacke_dozvole === '') {
          this.podnaslovVozacka = this.$t('DatumIstekaVozackeNijeUnet')
          this.vozackaVariant = 'danger'
          this.naslovVariant = 'danger'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        } else if (this.data.istekla_vozacka_dozvola) {
          this.podnaslovVozacka = this.$t('VozackaJeIstekla')
          this.vozackaVariant = 'danger'
          this.naslovVariant = 'danger'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        } else if (this.data.istice_vozacka) {
          this.podnaslovVozacka = this.$t('IsticeVozacka')
          this.vozackaVariant = 'warning'
          this.naslovVariant = 'warning'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        }

        if (this.data.datum_isteka_licence_instruktora === '') {
          this.podnaslovLicencaInstruktora = this.$t('LicencaInstruktoraNijeUneta')
          this.licencaInstruktoraVariant = 'danger'
          this.naslovVariant = 'danger'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        } else if (this.data.istekla_licence_instruktora) {
          this.podnaslovLicencaInstruktora = this.$t('LicencaInstruktoraJeIstekla')
          this.licencaInstruktoraVariant = 'danger'
          this.naslovVariant = 'danger'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        } else if (this.data.istice_licenca_instruktora) {
          this.podnaslovLicencaInstruktora = this.$t('LicencaInstruktoraIstice')
          this.licencaInstruktoraVariant = 'warning'
          this.naslovVariant = 'warning'
          this.naslov = this.$t('Upozorenje')
          this.podnaslov = this.$t('MolimoVasProveriteveParametre. NeštoNijeURedu')
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
