<template>
  <b-row>
    <b-col
      lg="12"
      md="12"
    >
      <b-card
        class="card-transaction"
        no-body
      >
        <b-card-header>
          <b-card-title>
            {{ $t("PlaniranaPrakticnaObuka") }}
          </b-card-title>
        </b-card-header>

        <b-card-body>
          <b-table
            v-if="Object.entries(casovi).length"
            :items="casovi"
            :fields="poljaCasovi"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-table-simple
                  hover
                  small
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th class="col-3">
                        {{ $t("IDKandidata") }}
                      </b-th>
                      <b-td>{{ row.item.id_broj_kandidata }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-3">
                        {{ $t("Vozilo") }}
                      </b-th>
                      <b-td>{{
                        row.item.vozilo_naziv
                      }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-3">
                        {{ $t("KodCasa") }}
                      </b-th>
                      <b-td>{{
                        row.item.kod_casa
                      }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-3">
                        {{ $t("VremeZavrsetka") }}
                      </b-th>
                      <b-td>{{
                        row.item.vreme_zavrsetka
                      }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </template>
            <template #cell(id)="data">
              <b-button-group
                size="sm"
              >
                <router-link
                  :to="{
                    name: 'instruktori-casovi-prakticne-obuke-izmena',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Izmeni") }}
                  </b-button>
                </router-link>
              </b-button-group>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('ZaDanasNisuZakazaniCasoviPrakticneObuke') }}</span>
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BFormCheckbox,
  BAlert,
  BButtonGroup,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BTable,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BAlert,
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      casovi: [],
      poljaCasovi: [
        { key: 'detalji', label: '', thStyle: { width: '10px' } },
        { key: 'ime_prezime_kandidata', label: this.$i18n.t('Kandidat') },
        { key: 'ime_prezime_instruktora', label: this.$i18n.t('Instruktor') },
        { key: 'vreme_pocetka', label: this.$i18n.t('VremePocetka') },
        { key: 'id', label: this.$i18n.t('Akcije') },
      ],
    }
  },
  mounted() {
    this.pokupiPodatke()
  },
  methods: {
    pokupiPodatke() {
      this.$http
        .get('/autoskole-administratori/casovi-po-danasnji-datum')
        .then(response => {
          this.casovi = response.data
        })
    },
  },
}
</script>
